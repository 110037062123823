const coreMenu = {
  id: "coreMenu",
  router: {
    path: "/core/menu",
    name: "CoreMenu",
    component: () => import(/* webpackChunkName: "core-menu" */ "../../views/core/Menu.vue"),
    meta: {
      title: "数据资产目录",
    },
  },
};

const coreRole = {
  id: "coreRole",
  router: {
    path: "/core/role",
    name: "CoreRole",
    component: () => import(/* webpackChunkName: "core-role" */ "../../views/core/Role.vue"),
    meta: {
      title: "数据角色",
    },
  },
};

const coreRoleAdd = {
  id: "coreRoleAdd",
  router: {
    path: "/core/role/add",
    name: "CoreRoleAdd",
    component: () => import(/* webpackChunkName: "core-role-add" */ "../../views/core/RoleAdd.vue"),
    meta: {
      title: "添加角色",
    },
  },
};

const coreRoleDetail = {
  id: "coreRoleDetail",
  router: {
    path: "/core/role/detail/:id",
    name: "RoleDetail",
    component: () => import(/* webpackChunkName: "core-role-detail" */ "../../views/core/RoleDetail.vue"),
    meta: {
      title: "角色详情",
    },
  },
};

const coreLogs = {
  id: "coreLogs",
  router: {
    path: "/core/logs",
    name: "CoreLogs",
    component: () => import(/* webpackChunkName: "core-logs" */ "../../views/core/Logs.vue"),
    meta: {
      title: "操作日志",
    },
  },
};

const coreAuth = {
  id: "coreAuth",
  router: {
    path: "/core/auth",
    name: "CoreAuth",
    component: () => import(/* webpackChunkName: "core-auth" */ "../../views/core/Auth.vue"),
    meta: {
      title: "权限设置",
    },
  },
};

export default {
  coreMenu,
  coreRole,
  coreRoleAdd,
  coreRoleDetail,
  coreLogs,
  coreAuth,
};

export const routers = [
  {
    type: 1,
    list: [
      { id: "coreMenu", action: ["add", "delete", "update", "query", "audit"] },
      { id: "coreRole", action: ["add", "delete", "update", "query", "audit"] },
      { id: "coreRoleAdd", action: ["add", "delete", "update", "query", "audit"] },
      { id: "coreRoleDetail", action: ["add", "delete", "update", "query", "audit"] },
      { id: "coreLogs", action: ["add", "delete", "update", "query", "audit"] },
      { id: "coreAuth", action: ["add", "delete", "update", "query", "audit"] },
    ],
  },
];

export const menus = [
  {
    type: 1,
    list: [
      {
        title: "数据资产目录",
        path: "/core/menu",
      },
      {
        title: "数据角色",
        path: "/core/role",
      },
      {
        title: "操作日志",
        path: "/core/logs",
      },
      {
        title: "权限设置",
        path: "/core/auth",
      },
    ],
  },
];
