<template>
    <div class="s-img" :class="[size ? `s-img-${size}` : null]" @click="view">
        <img :src="src">
        <div class="s-img-play" v-if="video">
            <a-icon type="play-circle" class="s-play-icon" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "s-img",
        props: {
            src: String,
            size: String,
            video: String
        },
        methods: {
            view() {
                if(this.video) {
                    this.$store.commit("setViewVideo", {
                        url: this.video,
                        poster: this.src,
                        title: "视频预览"
                    });
                } else if(this.src) {
                    this.$store.dispatch("showImgList", [{title: "图片预览", url: this.src}]);
                }
            }
        }
    }
</script>

<style lang="less">
.s-img {
    position: relative;
    width: 200px;
    height: 100px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &.s-img-small {
        width: 80px;
        height: 40px;
    }
    .s-img-play {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.0.5);
    }
    .s-play-icon {
        font-size: 32px;
        color: rgba(255,255,255,0.8);
    }
}
</style>
