const dev = false;
const localHost = 'http://lingyin.qihe.local';
const proHost = 'https://api.lingyinsi.cloud';
const devHost = dev ? localHost : proHost;
const host = process.env.NODE_ENV === 'production' ? proHost : devHost

export default {
  host,
  token: null,
  CorpID: 'wx637d78c358f30eb4',// 服务商CorpID
  SuiteID: 'wx12fcfb6b0255afba',
  AgentId: 1000045
}
