const USER_KEY = "user";

let storageData = sessionStorage.getItem(USER_KEY);
try {
    storageData = storageData ? JSON.parse(storageData) : {};
} catch (e) {
    console.error(e);
}

const state = {
    INFO: storageData["INFO"],
    TEMPLE: storageData["TEMPLE"],
    TOKEN: storageData["TOKEN"],
    UID: storageData["UID"],
    WX_ID: storageData["WX_ID"],
};

// getters
const getters = {
    INFO: state => state.INFO,
    TEMPLE: state => state.TEMPLE,
    TOKEN: state => state.TOKEN,
    UID: state => state.UID,
    WX_ID: state => state.WX_ID,
};

// mutations
const mutations = {
    setInfo(state, value) {
        state.INFO = value;
    },
    setTemple(state, value) {
        state.TEMPLE = value;
    },
    setToken(state, value) {
        state.TOKEN = value;
    },
    setUid(state, value) {
        state.UID = value;
    },
    setWxId(state, value) {
        state.WX_ID = value;
    },
};

const actions = {

};

export default {
    state,
    getters,
    mutations,
    actions
}
