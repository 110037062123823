import axios from "../js/req";
import {getDate, getKeyTitle} from "../js/tool";
import {getTemple} from "../js/storage";

/**
 * 佛事价格配置
 * key: 佛事子类别
 * obj.base: obj.base[0] 个人价格 obj.base[1] 团体价格
 * obj.extra 多加一个牌位价格
 * obj.min 礼忏诵经基础价格人数
 * obj.each 礼忏诵经每增加1人价格
 */
export const buddhismPriceConfig = {
    "01": {
        base: [16800, 32800],
        extra: 3200
    },
    "02": {
        base: [16800, 16800],
        extra: 3200
    },
    "03": {
        base: [3200, 3200],
        extra: 3200
    },
    "04": {
        base: [3200, 3200],
        extra: 3200
    },
    "05": {
        base: [3800, 3800],
        extra: 380,
        min: 10,
        each: 500
    },
    "06": {
        base: [3800, 3800],
        extra: 380,
        min: 10,
        each: 500
    },
    "07": {
        base: [3800, 3800],
        extra: 380,
        min: 14,
        each: 500
    },
    "08": {
        base: [6800, 6800],
        extra: 380,
        min: 17,
        each: 500
    },
}
/**
 * 法会价格配置
 * key: 法会子类别
 * value: 价格
 */
const pujaPriceConfig = {
    "01": 1000,
    "02": 50,
    "03": 1000,
    "04": 50,
    "05": 3000,
    "06": 300,
    "07": 10000,
    "08": 3000,
    "09": 300,
    "10": 10000,
}

export const buddhaCategoryList = [
    {key: 1, title: '日常佛事'},
    {key: 2, title: '随喜功德'},
    {key: 3, title: '大型法会'},
]

export const reserveTimeList = [
    {key: 1, title: '上午'},
    {key: 2, title: '下午'},
    {key: 3, title: '晚上'},
    {key: 4, title: '全天'},
]

export const buddhaTypeSetTabs = [
    {key: 1, title: '预约时间设置'},
    {key: 2, title: '礼忏诵经选项'},
    {key: 3, title: '支付设置'},
    {key: 4, title: '单资设置'},
]

/**
 * 法会通用牌位列表
 * */
const pujaTabletTypeList = [
    {key: "02", title: "延生小牌位"},
    {key: "04", title: "往生小牌位"},
    {key: "01", title: "延生大牌位"},
    {key: "03", title: "往生大牌位"},
]

/**
 * 佛事类型配置
 * */
export const buddhaTypeList = [
    {
        key: 1,
        title: '普佛',
        category: 1,
        tabKeys: [1, 3, 4],
        subTypes: [
            {key: "01", title: '单独延生普佛'},
            {key: "02", title: '单独往生普佛'},
            {key: "03", title: '众信延生普佛'},
            {key: "04", title: '众信往生普佛'}
        ]
    },
    {
        key: 2,
        title: '礼忏诵经',
        category: 1,
        tabKeys: [1, 2, 3, 4],
        subTypes: [{key: "05", title: '礼忏诵经（延生）'}, {key: "06", title: '礼忏诵经（往生）'}]
    },
    {
        key: 3,
        title: '焰口',
        category: 1,
        tabKeys: [1, 3, 4],
        person: {num: 2, max: 4}, // 牌位输入配置 num 数量 max 字数
        posterity: {num: 2, max: 4}, // 阳上输入配置 num 数量 max 字数
        subTypes: [{key: "07", title: '焰口一大士'}, {key: "08", title: '焰口三大士'}]
    },
    {key: 4, title: '祈福', category: 1, noPrice: true},
    {key: 5, title: '开光', category: 1, noPrice: true},
    {key: 6, title: '上供', category: 1, noPrice: true},
    {key: 7, title: '如意斋', category: 1, tabKeys: [3], paySet: 'single'},
    {key: 8, title: '罗汉斋', category: 1, tabKeys: [3], paySet: 'single'},
    {key: 9, title: '上堂斋', category: 1, noPrice: true},
    {key: 10, title: '放生', category: 2, tabKeys: [3], paySet: 'single', noPrice: true},
    {key: 11, title: '斋僧', category: 2, tabKeys: [3], paySet: 'single', noPrice: true},
    {key: 12, title: '建寺', category: 2, tabKeys: [3], paySet: 'single', noPrice: true},
    {key: 13, title: '梁皇法会', category: 3, tabKeys: [3], paySet: 'single', subTypes: [...pujaTabletTypeList]},
    {key: 14, title: '华严法会', category: 3, tabKeys: [3], paySet: 'single', subTypes: [...pujaTabletTypeList]},
    {key: 15, title: '地藏法会', category: 3, tabKeys: [3], paySet: 'single', subTypes: [...pujaTabletTypeList]},
    {
        key: 16,
        title: '水陆法会',
        category: 3,
        tabKeys: [3],
        paySet: 'single',
        subTypes: [
            {key: "06", title: '大坛延生小牌位'},
            {key: "09", title: '大坛往生小牌位'},
            {key: "05", title: '大坛延生大牌位'},
            {key: "08", title: '大坛往生大牌位'},
            {key: "07", title: '内坛延生牌位'},
            {key: "10", title: '内坛往生牌位'},
        ]
    },
    {key: 17, title: '盂兰盆法会', category: 3, tabKeys: [3], paySet: 'single', subTypes: [...pujaTabletTypeList]},
]

/**
 * 是否需要开牌
 */

export const getBuddhaNeedOpen = function(temp) {
    return [2,3].indexOf(temp.fs_type) >= 0
}

/**
 * 获取佛事大类名称
 * */
export const getBuddhaCategoryName = function (cat) {
    return getKeyTitle(buddhaCategoryList, cat);
}

/**
 * 获取佛事类型名称
 * */
export const getBuddhaTypeName = function (type) {
    return getKeyTitle(buddhaTypeList, type);
}

export const getReserveTimeText = function (time) {
    return getKeyTitle(reserveTimeList, time);
}

/**
 * 获取佛事大类下佛事类型列表
 * */
export const getBuddhaCategoryTypeList = function (cat) {
    return buddhaTypeList.filter(t => t.category == cat).map(item => {
        return {key: item.key, title: item.title}
    });
}

/**
 * 根据佛事类型获取配置tab列表
 * */
export const getBuddhaTypeTabs = function (type) {
    let res = [];
    let bt = buddhaTypeList.find(t => t.key == type);
    if (bt && bt.tabKeys) {
        res = bt.tabKeys.map(key => {
            return buddhaTypeSetTabs.find(tab => tab.key == key);
        })
    }
    return res;
}

export const hasExplanation = function (type) {
    return [1, 2, 3, 13, 14, 15, 16, 17].indexOf(type) !== -1;
}

/**
 * 获取佛事类型配置
 * */
export const getBuddhaTypeConfig = function (type) {
    return buddhaTypeList.find(t => t.key == type);
}

/**
 * 获取线下登记佛事大类列表
 * */
export const getBuddhaOfflineTypeList = function () {
    return buddhaCategoryList.filter(cat => cat.key != 2).map(cat => {
        return {
            ...cat,
            list: buddhaTypeList.filter(t => t.category == cat.key).filter(t => t.subTypes && t.subTypes.length > 0)
        }
    })
}

/**
 * 获取佛事小类名称
 * */
export const getBuddhaSubTypeName = function (type, sub) {
    let res = null;
    let conf = getBuddhaTypeConfig(type);
    if(conf && conf.subTypes) {
        res = getKeyTitle(conf.subTypes, sub);
    }
    return res;
}

export const getBuddhismShowName = function (buddhism) {
    const { temp, type } = buddhism;
    let res = getBuddhaTypeName(temp?.fs_type);
    if (temp && type) {
        const { fs_type } = temp;
        // 普佛 焰口显示子类别
        if ([1, 3].includes(temp.fs_type)) {
            res = getBuddhaSubTypeName(fs_type, type);
        }
        else if(temp.fs_type == 2) {
            if (!buddhism.bookType) {
                if(buddhism.detail) {
                    try {
                        const detail = JSON.parse(buddhism.detail);
                        buddhism = {
                            ...buddhism,
                            ...detail
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
            }
            if (buddhism.bookType) {
                res = `${buddhism.bookType == '' ? '礼' : '诵'}-${buddhism[buddhism.bookType]}`;
            }
        }
    }
    return res;
}

export const buddhaTypeSetValidator = (rule, value, callback) => {
    if (value) {
        if (value.error) {
            callback(new Error(value.error));
        } else {
            callback()
        }
    } else {
        callback(new Error('请设置预约时间'));
    }
}

/**
 * 处理接口返回的佛事类型配置信息
 * */
export const dealBuddhaTypeInfo = function (info) {
    try {
        if(info.reserve_config) {
            info.reserveTime = JSON.parse(info.reserve_config);
        }
        // 普佛的说明特殊处理
        if(info.detail && info.fs_type == 1) {
            try {
                const detail = JSON.parse(info.detail);
                for(let key in detail) {
                    info[key] = detail[key];
                }
            } catch (e) {
                console.error(e);
            }
        }
        if(info.other_config) {
            const otherConfig = JSON.parse(info.other_config);
            info.otherConfig = otherConfig;
            // 礼忏诵经选项
            if(info.fs_type == 2) {
                info.chantingOptions = {
                    scripture: otherConfig.scripture,
                    chanting: otherConfig.chanting
                };
            } else if(info.fs_type == 7 || info.fs_type == 8) {
                // 如意斋罗汉斋设置
                info.foodSet = {
                    maxNum: otherConfig.maxNum
                };
            } else if(info.fs_type == 3) {
                // 焰口诵经人数设置
                info.chantingNumbers = {
                    oneNumber: otherConfig.oneNumber,
                    threeNumber: otherConfig.threeNumber
                };
            }
            if(info.cat == 3 || info.cat == 1) {
                // 牌位上限
                info.maxTablet = otherConfig.maxTablet;
                // 法会开放日期
                if(info.cat == 3) {
                    info.pujaSet = [otherConfig.begin, otherConfig.end];
                }
            }
        }
        if(info.pay_config) {
            info.paySet = JSON.parse(info.pay_config);
        }
        if(info.price_config) {
            info.priceSet = JSON.parse(info.price_config);
        }
        if(info.allowance_config) {
            info.subsidyConfig = JSON.parse(info.allowance_config);
            info.grant = info.subsidyConfig.grant;
        }
    } catch (e) {
        console.error(e)
    }
}

/**
 * 获取单个佛事牌位价格
 * */
export const getTabletPrice = function (tablet, index, temp, rangeId) {
    if(!temp.priceSet && temp.price_config) {
        temp.priceSet = JSON.parse(temp.price_config);
    }
    const timeRangePrice = temp.priceSet ? temp.priceSet.timeRangePrice : null;
    let price = 0;
    if(timeRangePrice) {
        const config = timeRangePrice[tablet.type];
        const base = config ? config[rangeId] : null;
        if(base) {
            if(index == 0 || !temp.priceSet.diff) {
                let userType = tablet.userType || 1;
                price = base[userType - 1] || 0;
            } else {
                price = temp.priceSet.extra;
            }
        }
    }
    return price;
}

/**
 * 计算预约登记的佛事价格
 * */
export const calculateBuddhismPrice = function (temp, form) {
    const tabletList = form.tabletInfo;
    const cat = temp.cat;
    const timeRangePrice = temp.priceSet ? temp.priceSet.timeRangePrice : null;
    let price = 0, masterPrice = 0;
    //普佛价格计算
    if(cat == 1) {
        const rangeId = form.dateTime.range; // 选择的时间段
        if(tabletList && tabletList.length > 0) {
            if(timeRangePrice) {
                tabletList.forEach((item, i) => {
                    const tp = getTabletPrice(item, i, temp, rangeId);
                    item.price = tp * 100;
                    price += tp;
                });
                // 多出的诵经法师价格
                if(temp.fs_type == 2) {
                    const peopleNum = form.number || 0;
                    const options = [...temp.chantingOptions[form.bookType]];
                    options.sort((a, b) => a.number - b.number);
                    const min = options[0].number;
                    let offset = peopleNum - min;
                    offset = offset < 0 ? 0 :offset;
                    masterPrice = offset * temp.priceSet.masterPrice;
                    price += masterPrice;
                } else if(temp.fs_type == 3) {
                    const peopleNum = form.number || 0;
                    const options = tabletList[0].type == '07' ? temp.chantingNumbers.oneNumber : temp.chantingNumbers.threeNumber;
                    options.sort();
                    let offset = peopleNum - options[0];
                    offset = offset < 0 ? 0 :offset;
                    masterPrice = offset * temp.priceSet.masterPrice;
                    price += masterPrice;
                }
            } else if(temp.fs_type == 7 || temp.fs_type == 8){ // 如意斋 罗汉斋
                price += temp.priceSet && temp.priceSet.price ? temp.priceSet.price : 10000;
            }
        }
    } else if(cat == 3) {
        if(tabletList && tabletList.length > 0) {
            const config = temp.priceSet && temp.priceSet.pujaPrice ? temp.priceSet.pujaPrice : pujaPriceConfig;
            tabletList.forEach(item => {
                let p = config[item.type];
                if(p) {
                   price += p;
                }
                item.price = p || 0;
            })
        }
    }
    return {price: price * 100, masterPrice: masterPrice * 100};
}

/**
 * 获取佛事通用预约配置
 * */
export const getBuddhaNormalTimeConfig = function (temp) {
    let url = `/admin/fs-temp-reserve?pageSize=1&filter[is_common]=1&filter[temp_id]=${temp.id}`;
    return axios(url).then(res => {
        if(res.data.length > 0) {
            let normal = res.data[0];
            try {
                if(normal.config) {
                    let conf = JSON.parse(normal.config);
                    normal = {...normal, ...conf};
                }
            } catch (e) {
                console.error(e)
            }
            return normal
        } else {
            return null;
        }
    });
}

/**
 * 获取特殊时间段配置
 * @param temp fs-temp实体对象
 * @param time 月份第一天时间戳
 * @param flag 是否获取已预约记录
 * @returns {Promise<{}>}
 */
export const getBuddhaSpecialTimeConfig = function (temp, time, flag) {
    let month = new Date(time);
    month.setDate(1);
    let start = month.pattern("yyyy-MM-dd HH:mm:ss");
    month.setMonth(month.getMonth() + 1);
    let end = month.pattern("yyyy-MM-dd HH:mm:ss");
    let url = `/admin/fs-temp-reserve?pageSize=1000&filter[is_common]=0&filter[temp_id]=${temp.id}`;
    url += `&filter[date][gte]=${start}&filter[date][lt]=${end}`;
    if(flag) {
        let fsUrl = `/admin/fs?pageSize=1000&filter[temp_id]=${temp.id}&temple_id=${getTemple()}&dealed=1`;
        fsUrl += `&filter[date][gte]=${start}&filter[date][lt]=${end}`;
        return Promise.all([axios(url), axios(fsUrl, {noTempleFilter: true})]).then(pro => {
            let list = pro[0].data;
            let rl = pro[1].data;
            let special = {};
            list.forEach(item => {
                if(item.config) {
                    try {
                        let conf = JSON.parse(item.config);
                        special[item.date] = {...item, ...conf};
                    } catch (e) {
                        console.error(e)
                    }
                }
            });
            let reserve = {};
            rl.forEach(item => {
                if(item.begin_time && item.end_time) {
                    item.start = getDate(item.begin_time).pattern('HH:mm');
                    item.end = getDate(item.end_time).pattern('HH:mm');
                }
                if(item.tablet_info) {
                    try {
                        let tablet = JSON.parse(item.tablet_info);
                        if(tablet && tablet.length > 0) {
                            item.tabletType = tablet[0].type;
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
                if(reserve[item.date]) {
                    reserve[item.date].push(item);
                } else {
                    reserve[item.date] = [item]
                }
            })
            return {special, reserve};
        })
    } else {
        return axios(url).then(res => {
            let list = res.data;
            let special = {};
            list.forEach(item => {
                if(item.config) {
                    try {
                        let conf = JSON.parse(item.config);
                        special[item.date] = {...item, ...conf};
                    } catch (e) {
                        console.error(e)
                    }
                }
            });
            return special;
        });
    }
}
