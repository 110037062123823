const safeSmoke = {
  id: "safeSmoke",
  router: {
    path: "/safe/smoke",
    name: "SafeSmoke",
    component: () => import(/* webpackChunkName: "st" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "烟雾管理",
      key: "smoke",
    },
    redirect: { name: "SafeSmokeData" },
    children: [
      {
        path: "/safe/smoke/data",
        name: "SafeSmokeData",
        component: () => import(/* webpackChunkName: "sd" */ "../../views/safe/SafeData.vue"),
        meta: {
          title: "数据统计",
          key: "smoke",
        },
      },
      {
        path: "/safe/smoke/list",
        name: "SafeSmokeList",
        component: () => import(/* webpackChunkName: "sl" */ "../../views/safe/SmokeList.vue"),
        meta: {
          title: "设备列表",
          key: "smoke",
        },
      },
      {
        path: "/safe/smoke/record",
        name: "SafeSmokeRecord",
        component: () => import(/* webpackChunkName: "sr" */ "../../views/safe/SmokeRecord.vue"),
        meta: {
          title: "预警管理",
          key: "smoke",
        },
      },
      {
        path: "/safe/smoke/work",
        name: "SafeSmokeWork",
        component: () => import(/* webpackChunkName: "sw" */ "../../views/safe/SmokeWork.vue"),
        meta: {
          title: "预警事件处理",
          key: "smoke",
        },
      },
    ],
  },
};

const safeWater = {
  id: "safeWater",
  router: {
    path: "/safe/water",
    name: "SafeWater",
    component: () => import(/* webpackChunkName: "st" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "水压管理",
      key: "water",
    },
    redirect: { name: "SafeWaterData" },
    children: [
      {
        path: "/safe/water/data",
        name: "SafeWaterData",
        component: () => import(/* webpackChunkName: "wd" */ "../../views/safe/SafeData.vue"),
        meta: {
          title: "数据统计",
          key: "water",
        },
      },
      {
        path: "/safe/water/list",
        name: "SafeWaterList",
        component: () => import(/* webpackChunkName: "wl" */ "../../views/safe/WaterList.vue"),
        meta: {
          title: "设备列表",
          key: "water",
        },
      },
      {
        path: "/safe/water/record",
        name: "SafeWaterRecord",
        component: () => import(/* webpackChunkName: "wr" */ "../../views/safe/WaterRecord.vue"),
        meta: {
          title: "预警管理",
          key: "water",
        },
      },
      {
        path: "/safe/water/work",
        name: "SafeWaterWork",
        component: () => import(/* webpackChunkName: "ww" */ "../../views/safe/WaterWork.vue"),
        meta: {
          title: "预警事件处理",
          key: "water",
        },
      },
    ],
  },
};

const safePower = {
  id: "safePower",
  router: {
    path: "/safe/power",
    name: "SafePower",
    component: () => import(/* webpackChunkName: "st" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "电力管理",
      key: "power",
    },
    redirect: { name: "SafePowerData" },
    children: [
      {
        path: "/safe/power/data",
        name: "SafePowerData",
        component: () => import(/* webpackChunkName: "pd" */ "../../views/safe/SafeData.vue"),
        meta: {
          title: "数据统计",
          key: "power",
        },
      },
      {
        path: "/safe/power/list",
        name: "SafePowerList",
        component: () => import(/* webpackChunkName: "pl" */ "../../views/safe/PowerList.vue"),
        meta: {
          title: "设备列表",
          key: "power",
        },
      },
      {
        path: "/safe/power/record",
        name: "SafePowerRecord",
        component: () => import(/* webpackChunkName: "pr" */ "../../views/safe/PowerRecord.vue"),
        meta: {
          title: "预警管理",
          key: "power",
        },
      },
      {
        path: "/safe/power/work",
        name: "SafePowerWork",
        component: () => import(/* webpackChunkName: "pw" */ "../../views/safe/PowerWork.vue"),
        meta: {
          title: "预警事件处理",
          key: "power",
        },
      },
    ],
  },
};

const safeMonitor = {
  id: "safeMonitor",
  router: {
    path: "/safe/monitor",
    name: "SafeMonitor",
    component: () => import(/* webpackChunkName: "mt" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "监控管理",
      key: "monitor",
    },
    redirect: { name: "SafeMonitorData" },
    children: [
      {
        path: "/safe/monitor/data",
        name: "SafeMonitorData",
        component: () => import(/* webpackChunkName: "md" */ "../../views/safe/SafeData.vue"),
        meta: {
          title: "数据统计",
          key: "monitor",
        },
      },
      {
        path: "/safe/monitor/list",
        name: "SafeMonitorList",
        component: () => import(/* webpackChunkName: "ml" */ "../../views/safe/MonitorList.vue"),
        meta: {
          title: "设备列表",
          key: "monitor",
        },
      },
      {
        path: "/safe/monitor/record",
        name: "SafeMonitorRecord",
        component: () => import(/* webpackChunkName: "mr" */ "../../views/safe/MonitorRecord.vue"),
        meta: {
          title: "预警管理",
          key: "monitor",
        },
      },
      {
        path: "/safe/monitor/work",
        name: "SafeMonitorWork",
        component: () => import(/* webpackChunkName: "mw" */ "../../views/safe/MonitorWork.vue"),
        meta: {
          title: "预警事件处理",
          key: "monitor",
        },
      },
    ],
  },
};

const safeTraffic = {
  id: "safeTraffic",
  router: {
    path: "/safe/traffic",
    name: "SafeTraffic",
    component: () => import(/* webpackChunkName: "st" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "人流管理",
      key: "traffic",
    },
    redirect: { name: "SafeTrafficData" },
    children: [
      {
        path: "/safe/traffic/data",
        name: "SafeTrafficData",
        component: () => import(/* webpackChunkName: "td" */ "../../views/safe/TrafficData.vue"),
        meta: {
          title: "数据统计",
          key: "traffic",
        },
      },
      {
        path: "/safe/traffic/list",
        name: "SafeTrafficList",
        component: () => import(/* webpackChunkName: "tl" */ "../../views/safe/TrafficList.vue"),
        meta: {
          title: "设备列表",
          key: "traffic",
        },
      },
      {
        path: "/safe/traffic/record",
        name: "SafeTrafficRecord",
        component: () => import(/* webpackChunkName: "tr" */ "../../views/safe/TrafficRecord.vue"),
        meta: {
          title: "预警管理",
          key: "traffic",
        },
      },
    ],
  },
};

const safePlaque = {
  id: "safePlaque",
  router: {
    path: "/safe/plaque",
    name: "SafePlaque",
    component: () => import(/* webpackChunkName: "st" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "牌匾管理",
      key: "plaque",
    },
    redirect: { name: "SafePlaqueData" },
    children: [
      {
        path: "/safe/plaque/data",
        name: "SafePlaqueData",
        component: () => import(/* webpackChunkName: "plaque-d" */ "../../views/safe/SafeData.vue"),
        meta: {
          title: "数据统计",
          key: "plaque",
        },
      },
      {
        path: "/safe/plaque/list",
        name: "SafePlaqueList",
        component: () => import(/* webpackChunkName: "plaque-l" */ "../../views/safe/PlaqueList.vue"),
        meta: {
          title: "设备列表",
          key: "plaque",
        },
      },
      {
        path: "/safe/plaque/record",
        name: "SafePlaqueRecord",
        component: () => import(/* webpackChunkName: "plaque-r" */ "../../views/safe/PlaqueRecord.vue"),
        meta: {
          title: "预警管理",
          key: "plaque",
        },
      },
      {
        path: "/safe/plaque/work",
        name: "SafePlaqueWork",
        component: () => import(/* webpackChunkName: "plaque-w" */ "../../views/safe/PlaqueWork.vue"),
        meta: {
          title: "预警事件处理",
          key: "plaque",
        },
      },
    ],
  },
};

const safeMassif = {
  id: "safeMassif",
  router: {
    path: "/safe/massif",
    name: "SafeMassif",
    component: () => import(/* webpackChunkName: "st" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "山体管理",
      key: "massif",
    },
    redirect: { name: "SafeMassifData" },
    children: [
      {
        path: "/safe/massif/data",
        name: "SafeMassifData",
        component: () => import(/* webpackChunkName: "md" */ "../../views/safe/SafeData.vue"),
        meta: {
          title: "数据统计",
          key: "massif",
        },
      },
      {
        path: "/safe/massif/list",
        name: "SafeMassifList",
        component: () => import(/* webpackChunkName: "ml" */ "../../views/safe/MassifList.vue"),
        meta: {
          title: "设备列表",
          key: "massif",
        },
      },
      {
        path: "/safe/massif/record",
        name: "SafeMassifRecord",
        component: () => import(/* webpackChunkName: "mr" */ "../../views/safe/MassifRecord.vue"),
        meta: {
          title: "预警管理",
          key: "massif",
        },
      },
      {
        path: "/safe/massif/work",
        name: "SafeMassifWork",
        component: () => import(/* webpackChunkName: "mw" */ "../../views/safe/MassifWork.vue"),
        meta: {
          title: "预警事件处理",
          key: "massif",
        },
      },
    ],
  },
};

const safeMeteorology = {
  id: "safeMeteorology",
  router: {
    path: "/safe/meteorology",
    name: "SafeMeteorology",
    component: () => import(/* webpackChunkName: "st" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "气象管理",
      key: "meteorology",
    },
    redirect: { name: "SafeMeteorologyData" },
    children: [
      {
        path: "/safe/meteorology/data",
        name: "SafeMeteorologyData",
        component: () => import(/* webpackChunkName: "meteorology-d" */ "../../views/safe/MeteorologyData.vue"),
        meta: {
          title: "数据统计",
          key: "meteorology",
        },
      },
      {
        path: "/safe/meteorology/record",
        name: "SafeMeteorologyRecord",
        component: () => import(/* webpackChunkName: "meteorology-r" */ "../../views/safe/MeteorologyRecord.vue"),
        meta: {
          title: "预警管理",
          key: "meteorology",
        },
      },
    ],
  },
};

const safeDetail = {
  id: "safeDetail",
  router: {
    path: "/safe/detail/:id",
    name: "SafeDetail",
    component: () => import(/* webpackChunkName: "s-detail" */ "../../views/safe/SafeDetail.vue"),
    meta: {
      title: "预警详情",
    },
  },
};

const safeLevel = {
  id: "safeLevel",
  router: {
    path: "/safe/level/:id",
    name: "SafeLevel",
    component: () => import(/* webpackChunkName: "s-detail" */ "../../views/safe/SafeLevel.vue"),
    meta: {
      title: "预警设置",
    },
  },
};

const safeCar = {
  id: "safeCar",
  router: {
    path: "/safe/car",
    name: "SafeCar",
    component: () => import(/* webpackChunkName: "st" */ "../../views/safe/SafeTemplete.vue"),
    meta: {
      title: "车辆管理",
      key: "car",
    },
    redirect: { name: "SafeCarList" },
    children: [
      {
        path: "/safe/car/list",
        name: "SafeCarList",
        component: () => import(/* webpackChunkName: "cl" */ "../../views/safe/CarList.vue"),
        meta: {
          title: "车辆管理",
          key: "car",
        },
      },
      {
        path: "/safe/car/data",
        name: "SafeCarData",
        component: () => import(/* webpackChunkName: "cd" */ "../../views/safe/CarData.vue"),
        meta: {
          title: "数据统计",
          key: "car",
        },
      },
      {
        path: "/safe/car/form",
        name: "SafeCarForm",
        component: () => import(/* webpackChunkName: "cf" */ "../../views/safe/CarForm.vue"),
        meta: {
          title: "模板设置",
          key: "car",
        },
      },
      {
        path: "/safe/car/setting",
        name: "SafeCarSetting",
        component: () => import(/* webpackChunkName: "cs" */ "../../views/safe/CarSetting.vue"),
        meta: {
          title: "规则权限",
          key: "car",
        },
      },
      {
        path: "/safe/car/plate",
        name: "SafeCarPlate",
        component: () => import(/* webpackChunkName: "mw" */ "../../views/safe/CarPlate.vue"),
        meta: {
          title: "车牌下发",
          key: "car",
        },
      },
    ],
  },
};

const safeLogs = {
  id: "safeLogs",
  router: {
    path: "/safe/logs",
    name: "SafeLogs",
    component: () => import(/* webpackChunkName: "safe-logs" */ "../../views/safe/SafeLogs.vue"),
    meta: {
      title: "系统日志",
    },
  },
};
const safeAuth = {
  id: "safeAuth",
  router: {
    path: "/safe/auth",
    name: "SafeAuth",
    component: () => import(/* webpackChunkName: "safe-auth" */ "../../views/safe/SafeAuth.vue"),
    meta: {
      title: "权限设置",
    },
  },
};
// const safePlan = { id: "safePlan", entity: "plan" };

export const routers = [
  {
    type: 1,
    list: [
      {
        id: "safeSmoke",
        key: "1",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeWater",
        key: "2",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safePower",
        key: "3",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeMonitor",
        key: "4",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeTraffic",
        key: "5",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safePlaque",
        key: "6",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeMassif",
        key: "7",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeMeteorology",
        key: "8",
        action: ["add", "delete", "update", "query", "audit"],
      },
      // {
      //   id: "sentiment",
      //   key: "9",
      //   action: ["add", "delete", "update", "query", "audit"],
      // },
      {
        id: "safeDetail",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeLevel",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeCar",
        key: "10",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeLogs",
        key: "11",
        action: ["add", "delete", "update", "query", "audit"],
      },
      {
        id: "safeAuth",
        key: "12",
        action: ["update", "query"],
      },
      // {
      //   id: "safePlan",
      //   key: "13",
      //   action: ["update", "query"],
      // },
    ],
  },
];

export const menus = [
  {
    type: 1,
    list: [
      {
        title: "数字看板",
        path: "/safe/board",
        children: [
          {
            title: "烟雾管理",
            path: "/safe/smoke",
            key: "1",
          },
          {
            title: "水压管理",
            path: "/safe/water",
            key: "2",
          },
          {
            title: "电力管理",
            path: "/safe/power",
            key: "3",
          },
          {
            title: "监控管理",
            path: "/safe/monitor",
            key: "4",
          },
          {
            title: "人流管理",
            path: "/safe/traffic",
            key: "5",
          },
          {
            title: "牌匾管理",
            path: "/safe/plaque",
            key: "6",
          },
          {
            title: "山体管理",
            path: "/safe/massif",
            key: "7",
          },
          {
            title: "气象管理",
            path: "/safe/meteorology",
            key: "8",
          },
          // {
          //   title: "舆情管理",
          //   path: "/safe/sentiment",
          //   key: "9",
          // },
        ],
      },
      {
        title: "业务处理",
        path: "/safe/business",
        children: [
          {
            title: "车辆管理",
            path: "/safe/car",
            key: "10",
          },
        ],
      },
      {
        title: "系统设置",
        path: "/safe/setting",
        children: [
          {
            title: "日志列表",
            path: "/safe/logs",
            key: "11",
          },
          {
            title: "权限设置",
            path: "/safe/auth",
            key: "12",
          },
          // {
          //   title: "应急预案",
          //   path: "/entity/plan",
          //   key: "13",
          // },
        ],
      },
    ],
  },
];

export default {
  safeSmoke,
  safeWater,
  safePower,
  safeMonitor,
  safeTraffic,
  safePlaque,
  safeMassif,
  safeMeteorology,
  safeDetail,
  safeLevel,
  safeCar,
  safeLogs,
  safeAuth,
  // safePlan,
};
