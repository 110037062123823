import { render, staticRenderFns } from "./child-temp.vue?vue&type=template&id=12deab72&scoped=true"
import script from "./child-temp.vue?vue&type=script&lang=js"
export * from "./child-temp.vue?vue&type=script&lang=js"
import style0 from "./child-temp.vue?vue&type=style&index=0&id=12deab72&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12deab72",
  null
  
)

export default component.exports