import {wxConfig} from "../../common/js/wxTool";

const state = {
    update: null,
    hasConfig: "init", // wx.agentConfig 状态 init初始化 loading正在进行 success成功 error失败
};

// getters
const getters = {
    update: state => state.update,
    hasConfig: state => state.hasConfig,
};

// mutations
const mutations = {
    changeUpdate(state, value) {
        state.update = value;
    },
    changeHasConfig(state, value) {
        state.hasConfig = value;
    },
};

const actions = {
    WWAgentConfig(context) {
        if(context.getters.hasConfig === "loading") return;
        context.commit("changeHasConfig", "loading");
        wxConfig([]).then(() => {
            context.commit("changeHasConfig", "success");
        }).catch((error) => {
            console.error(error);
            context.commit("changeHasConfig", "error")
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}
