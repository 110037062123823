import axios from 'axios';
import conf from "./config";
import {getTemple} from "./storage";
import { message} from 'ant-design-vue';
import {getToken} from "./storage";

const request = axios.create({
  headers: {'Content-Type': 'application/json'}
});
const openApi = ['/admin/login/auth', '/common/get-data', '/live/get-data', '/admin/login/get-code'];
const noTempleFilterApi = openApi.concat(['/admin/common/get-dept', '/admin/common/get-dept-user', '/admin/common/get-user']);

let hide = null, timer = null;
const hideLoading = function() {
  if(timer) {
    clearTimeout(timer);
    timer = null;
  } else {
    typeof hide === 'function' && hide();
    hide = null;
  }
}

request.interceptors.request.use(config => {
  let token = getToken();
  if(openApi.indexOf(config.url) == -1 && !config.open && !token) {
    location.replace('/login?r=' + location.pathname);
    return null;
  }
  if (!/^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/.test(config.url)) {
    let url = config.url;
    // 添加寺院筛选
    if(!noTempleFilterApi.some(api => url.indexOf(api) >= 0) && !config.noTempleFilter) {
      let method = config.method ? config.method.toUpperCase() : 'GET';
      let temple = getTemple();
      if(temple) {
        if(method == 'GET') {
          url += `${url.indexOf('?') >= 0 ? '&' : '?'}filter[temple_id]=${temple}`
        } else if(method == 'POST') {
          config.data.temple_id = temple;
        }
      }
    }
    let data = config.data;
    if(data){
      // 处理当PATCH时，select字段无法删除的问题
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if (value === undefined) {
            data[key] = null;
          }
        }
      }
    }
    config.url = conf.host + url;
  }
  let headers = {};
  token && (headers["Authorization"] = 'Bearer ' + token);
  config.headers = Object.assign(headers, config.headers);
  if(config.loadingText) {
    timer = setTimeout(() => {
      let text = config.loadingText;
      hide = message.loading(text, 0);
      timer = null;
    }, 300);
  }
  // config.withCredentials = true;
  return config;
}, err => {
  message.warning('请求超时');
  return Promise.reject(err);
});

request.interceptors.response.use(res => {
  hideLoading();
  let status = res.status;
  if (status && /20[0-9]/.test(status + '')) {
    return res.data;
  }  else {
    return Promise.reject(res);
  }
}, err => {
  hideLoading();
  let res = err.response;
  if(res) {
    let status = res.status;
    if(status == 401) {
      location.replace('/login?r=' + location.pathname);
    } else if(status == 403) {
      message.warning('您没有权限');
    } else {
      if(Object.prototype.toString.call(res.data) === '[object Blob]') {
        const reader = new FileReader();
        reader.onload = () => {
          const data = JSON.parse(reader.result);
          if(data.message) {
            message.warning(data.message);
          } else {
            message.warning('请求错误：' + status);
          }
        };
        reader.onerror = () => {
          message.warning('请求错误：' + status);
        }
        reader.readAsText(res.data, 'utf-8');
      } else if(res.data && res.data.message) {
        message.warning(res.data.message);
      } else {
        message.warning('请求错误：' + status);
      }
    }
  } else {
    message.warning('系统繁忙');
  }
  return Promise.reject(err);
});

export default request;

export const buddhismRequest = function (uri, data = {}) {
  let url = `/weixin/common/third-request?uri=${uri}`
  return axios({url, method: 'POST', noTempleFilter: true, data}).then(res => {
    if(res.code == 1) {
      return res.data;
    } else {
      message.warning(res.msg);
    }
  });
}
