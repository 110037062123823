import store from "../../store";
export const DATA_KEY = "user";

/**
 * 存储用户登陆信息
 * @param data 登陆信息实体
 * @param key 登陆信息key值 不传表示全部
 */
const setStorageData = (data, key) => {
  if (key) {
    const str = sessionStorage.getItem(DATA_KEY);
    const obj = str ? JSON.parse(str) : {};
    obj[key] = data;
    sessionStorage.setItem(DATA_KEY, JSON.stringify(obj));
  } else if(data) {
    sessionStorage.setItem(DATA_KEY, JSON.stringify(data));
  }
}

const getToken = function() {
  return store.getters.TOKEN;
};

const getUserId = function () {
  return store.getters.UID;
}

const getUserWxId = function() {
  return store.getters.WX_ID;
}

const getStorageInfo = function() {
  return store.getters.INFO;
}

const getUser = function () {
  return store.getters.INFO;
}

const setUser = function (user) {
  store.commit("setInfo", user);
  setStorageData(user, "INFO");
}

const setToken = function(res) {
  let admin = res.user;
  const data = {
    INFO: admin,
    TEMPLE: admin.temple_id,
    TOKEN: res.token,
    UID: admin.id,
    WX_ID: admin.wx_user_id
  }
  store.commit("setInfo", data.INFO);
  store.commit("setTemple", data.TEMPLE);
  store.commit("setToken", data.TOKEN);
  store.commit("setUid", data.UID);
  store.commit("setWxId", data.WX_ID);
  sessionStorage.setItem(DATA_KEY, JSON.stringify(data));
};

const getStorage = function(key) {
  let result = null;
  let storage = sessionStorage.getItem(key);
  try {
    storage = JSON.parse(storage);
    result = storage;
  } catch (e) {
    console.log(`Storage for ${key} parse failed.`);
  }
  return result;
};

const getStorageData = function() {
  return getStorage(DATA_KEY) || {};
};

const getTemple = function () {
  return store.getters.TEMPLE;
}

const cleanToken = function() {
  store.commit("setInfo", undefined);
  store.commit("setTemple", undefined);
  store.commit("setToken", undefined);
  store.commit("setUid", undefined);
  store.commit("setWxId", undefined);
  sessionStorage.removeItem(DATA_KEY);
};

export { getToken, getUserId, getStorage, setToken, cleanToken, getStorageInfo, getTemple, getUserWxId, getUser, setUser, getStorageData }
