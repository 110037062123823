const buddhismWorkbench =  {
    id: 'buddhismWorkbench',
    router: {
        path: '/buddha/bench',
        name: 'BuddhismWorkbench',
        component: () => import(/* webpackChunkName: "bb" */ '../../views/buddha/BuddhismWorkbench.vue'),
        meta: {
            title: '工作台'
        }
    },
}
const buddhismOrder =  {
    id: 'buddhismOrder',
    router: {
        path: '/buddha/bo',
        name: 'BuddhismOrder',
        component: () => import(/* webpackChunkName: "bo" */ '../../views/buddha/BuddhismOrder.vue'),
        meta: {
            title: '订单管理'
        }
    },
}
const buddhismList =  {
    id: 'buddhismList',
    router: {
        path: '/buddha/bl',
        name: 'BuddhismList',
        component: () => import(/* webpackChunkName: "bo" */ '../../views/buddha/BuddhismList.vue'),
        meta: {
            title: '预约设置'
        }
    },
}

const buddhismClaim =  {
    id: 'buddhismClaim',
    router: {
        path: '/buddha/bc',
        name: 'BuddhismClaim',
        component: () => import(/* webpackChunkName: "bo" */ '../../views/buddha/BuddhismClaim.vue'),
        meta: {
            title: '认领台'
        }
    },
}

const buddhismBoard =  {
    id: 'buddhismClaim',
    router: {
        path: '/buddha/board',
        name: 'BuddhismBoard',
        component: () => import(/* webpackChunkName: "bo" */ '../../views/buddha/BuddhaBoard.vue'),
        meta: {
            title: '佛事看板'
        }
    },
}
const buddhismEnrollList =  {
    id: 'buddhismEnrollList',
    router: {
        path: '/buddha/bel',
        name: 'BuddhismEnrollList',
        component: () => import(/* webpackChunkName: "bel" */ '../../views/buddha/BuddhismEnrollList.vue'),
        meta: {
            title: '登记列表',
            keepAlive: true
        }
    },
}

const buddhismDetail =  {
    id: 'buddhismDetail',
    router: {
        path: '/buddha/bd/:id',
        name: 'BuddhismDetail',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/buddha/BuddhismDetail.vue'),
        meta: {
            title: '登记详情'
        }
    },
}
const buddhismTablets =  {
    id: 'buddhismTablets',
    router: {
        path: '/buddha/bt',
        name: 'BuddhismTablets',
        component: () => import(/* webpackChunkName: "bt" */ '../../views/buddha/BuddhismTablets.vue'),
        meta: {
            title: '牌位列表'
        }
    },
}
const buddhaTypeAdd =  {
    id: 'buddhaTypeAdd',
    router: {
        path: '/buddha/ba',
        name: 'BuddhaTypeAdd',
        component: () => import(/* webpackChunkName: "ba" */ '../../views/buddha/BuddhaTypeAdd.vue'),
        meta: {
            title: '新增预约'
        }
    },
}
const buddhaTypeDetail =  {
    id: 'buddhaTypeDetail',
    router: {
        path: '/buddha/bt/:id',
        name: 'BuddhaTypeDetail',
        component: () => import(/* webpackChunkName: "bt" */ '../../views/buddha/BuddhaTypeDetail.vue'),
        meta: {
            title: '设置详情'
        }
    },
}

const buddhismAdd =  {
    id: 'buddhismAdd',
    router: {
        path: '/buddha/register',
        name: 'BuddhismAdd',
        component: () => import(/* webpackChunkName: "register" */ '../../views/buddha/BuddhismAdd.vue'),
        meta: {
            title: '线下登记'
        }
    },
}

const tabletBlacklist =  {
    id: 'tabletBlacklist',
    router: {
        path: '/buddha/tb',
        name: 'TabletBlacklist',
        component: () => import(/* webpackChunkName: "tb" */ '../../views/buddha/TabletBlacklist.vue'),
        meta: {
            title: '敏感数据库'
        }
    },
}
const buddhismSubsidy =  {
    id: 'buddhismSubsidy',
    router: {
        path: '/buddha/bs',
        name: 'BuddhismSubsidy',
        component: () => import(/* webpackChunkName: "bs" */ '../../views/buddha/BuddhismSubsidy.vue'),
        meta: {
            title: '单资补贴'
        }
    },
}
const buddhaAnnounce = {id: 'announce', entity: 'announce'};
const buddhaNotice =  {
    id: 'buddhaNotice',
    router: {
        path: '/buddha/bn',
        name: 'BuddhaNotice',
        component: () => import(/* webpackChunkName: "bn" */ '../../views/buddha/BuddhaNotice.vue'),
        meta: {
            title: '消息通知'
        }
    },
}
const buddhismAdmin =  {
    id: 'buddhismAdmin',
    router: {
        path: '/buddha/admin',
        name: 'BuddhismAdmin',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/buddha/BuddhismAdmin.vue'),
        meta: {
            title: '权限设置'
        }
    },
}
const buddhaBirthday =  {
    id: 'buddhaBirthday',
    router: {
        path: '/buddha/bbd',
        name: 'BuddhaBirthday',
        component: () => import(/* webpackChunkName: "ba" */ '../../views/buddha/BuddhaBirthday.vue'),
        meta: {
            title: '佛诞日设置'
        }
    },
}
const freeBuddhism =  {
    id: 'freeBuddhism',
    router: {
        path: '/buddha/fb',
        name: 'FreeBuddhism',
        component: () => import(/* webpackChunkName: "fb" */ '../../views/buddha/FreeBuddhism.vue'),
        meta: {
            title: '单资补贴设置'
        }
    },
}

const master = {id: 'master', entity: 'master'};
const tk = {id: 'tk', entity: 'tk'};
const timeRange = {id: 'timeRange', entity: 'timeRange'};
const palace = {id: 'palace', entity: 'palace'};

export default {
    buddhismWorkbench,
    buddhismOrder,
    buddhismList,
    buddhismEnrollList,
    buddhismClaim,
    buddhismBoard,
    buddhismDetail,
    buddhismTablets,
    buddhaTypeAdd,
    buddhaTypeDetail,
    buddhismAdd,
    tabletBlacklist,
    buddhismSubsidy,
    buddhaAnnounce,
    buddhaNotice,
    buddhismAdmin,
    buddhaBirthday,
    master,
    tk,
    timeRange,
    palace,
    freeBuddhism
}

export const routers = [
    {
        type: 1,
        list: [
            {id: 'buddhismWorkbench', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismOrder', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismClaim', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismBoard', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismEnrollList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismTablets', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaTypeAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaTypeDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'tabletBlacklist', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaNotice', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismSubsidy', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaAnnounce', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismAdmin', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaBirthday', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'master', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'tk', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'timeRange', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'palace', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'freeBuddhism', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    },
    {
        type: 2,
        list: [
            {id: 'buddhismWorkbench', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismOrder', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismClaim', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismBoard', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismEnrollList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismTablets', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaTypeAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaTypeDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'tabletBlacklist', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaNotice', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismSubsidy', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaAnnounce', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaBirthday', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'master', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'tk', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'timeRange', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'palace', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'freeBuddhism', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    },
    {
        type: 3,
        list: [
            {id: 'buddhismWorkbench', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismBoard', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhismDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'buddhaNotice', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    }
]
export const menus = [
    {
        type: 1,
        list: [
            {
                title: '佛事看板',
                path: '/buddha/board',
            },
            {
                title: '工作台',
                path: '/buddha/bench',
                icon: 'desktop'
            },
            {
                title: '通知公告',
                path: '/entity/announce'
            },
            {
                title: '佛事设置',
                path: '/buddha/reserve',
                children: [
                    {
                        title: '预约设置',
                        path: '/buddha/bl',
                    },
                    {
                        title: '殿堂设置',
                        path: '/entity/palace'
                    },
                    {
                        title: '时间设置',
                        path: '/entity/timeRange'
                    },
                    {
                        title: '佛诞日设置',
                        path: '/buddha/bbd'
                    },
                ]
            },
            {
                title: '登记列表',
                path: '/buddha/bel',
            },
            {
                title: '订单列表',
                path: '/buddha/bo',
                icon: 'profile'
            },
            {
                title: '牌位打印',
                path: '/buddha/bt',
            },
            {
                title: '法师管理',
                path: '/entity/master'
            },
            {
                title: '堂口管理',
                path: '/entity/tk'
            },
            {
                title: '单资补贴',
                path: '/buddha/bs'
            },
            {
                title: '权限设置',
                path: '/buddha/admin'
            },
            {
                title: '敏感数据库',
                path: '/buddha/tb',
            },
        ]
    },
    {
        type: 2,
        list: [
            {
                title: '工作台',
                path: '/buddha/bench',
                icon: 'desktop'
            },
            {
                title: '佛事看板',
                path: '/buddha/board',
            },
            {
                title: '登记列表',
                path: '/buddha/bel',
                icon: 'flag'
            },
            {
                title: '订单列表',
                path: '/buddha/bo',
                icon: 'profile'
            },
            {
                title: '牌位打印',
                path: '/buddha/bt',
            },
            {
                title: '敏感数据库',
                path: '/buddha/tb',
            },
        ]
    },
    {
        type: 3,
        list: [
            {
                title: '工作台',
                path: '/buddha/bench',
                icon: 'desktop'
            },
            {
                title: '佛事看板',
                path: '/buddha/board',
            },
        ]
    }
]
