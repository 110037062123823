import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import info from './modules/info'
import light from './modules/light'
import status from './modules/status'
import temp from './modules/temp'
import book from "./modules/book";
import buddha from "./modules/buddha";
import safe from "./modules/safe";
import viewer from "./modules/viewer";
import user from "./modules/user";


Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    login,
    info,
    light,
    status,
    temp,
    book,
    buddha,
    safe,
    viewer,
    user,
  },
  strict: debug
})
